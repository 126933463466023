.MuiDrawer-root {
  .MuiPaper-root {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    .MuiList-root {
      padding: 0;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
    .menu-item {
      padding: 8px 0;
      color: inherit;
      &__logo {
        font-size: 1.5rem;
        font-weight: 500;
        padding: 2px 24px;
        margin-bottom: 16px;
        svg {
          width: 60%;
          height: auto;
          padding: 16px;
          margin: auto;
        }
      }
    }
  }
}