.MuiContainer-root.dashboard-header-logo {
  max-width: 100%;
  margin-bottom: 2rem;
  & > img {
    width: 180px;
  }
}
main.MuiContainer-root.dashboard-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
  .loader {
    display: flex;
    min-height: 100px;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .stores-list {
    tbody {
      tr {
        &:nth-child(even) {
          background-color: rgba(0, 0, 0, .05);
        }
        &:nth-child(odd) {
          background-color: rgba(255, 255, 255, 1);
        }
      }
      td {
        .pump-status,
        .alarm-status,
        .electric-generator-status {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          gap: 10px;
          width: auto;
          align-items: center;
          padding: 8px;
          border-radius: 8px;
          & > * {
            text-align: center;
          }
        }
        .pump-status > * {
          min-width: 87px;
          text-align: center;
        }
        .electric-generator-status {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          .phase {
            display: flex;
            gap: 20px;
            & > * {
              border: 1px solid rgba(0, 0, 0, 0.1);
              padding: 8px;
              border-radius: 8px;
            }
          }
          .fuel-level {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            text-align: center;
          }
        }
      }
    }
  }
  .MuiSwitch-root {
    .MuiSwitch-switchBase:not(.Mui-checked) {
      .MuiSwitch-thumb {
        color: rgba(255, 0, 0, 1)
      }
    }
    .MuiSwitch-track {
      background-color: rgba(255, 0, 0, 1);
    }
  }
}
.store, .title {
  cursor: pointer;
  display: flex;
  // padding: 1em 2em;
  padding: 0;
  margin: 1em 0;
  flex: 1 0 100%;
  transition: .3s border-radius ease;
  width: 1200px;
  // .store-name,
  &__pump-status,
  &__alarm-status,
  .pump-status,
  .alarm-status,
  .electric-generator-status {
    display: flex;
    flex-grow: 1;
    justify-content: space-evenly;
    padding: 0.5em;
    align-content: center;
    flex-wrap: wrap;
    text-align: center;
    margin:0 2em;
  }
  .pump-status,
  .alarm-status,
  .electric-generator-status {
    border-radius: 4em;
    background-color: rgba(0, 0, 0, 0.05);
    box-shadow:
      3px 2px 5px rgba(0, 0, 0, .1) inset,
      -3px -3px 5px rgba(255, 255, 255, .75) inset,
      0 3px 50px rgba(0,0,0,0.1);
    @media (max-width: 600px) {
      border-radius: 1rem;
    }
    @media (max-width: 400px) {
      border-radius: .5rem;
    }
  }
  .store-name {
    margin-left: 2rem;
    width: 3rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 600px) {
      margin: .5em auto;
    }
  }
  .pump-status {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 2;
    height: auto;
    padding: 0.5em 0;
    @media (max-width: 600px) {
      margin: 0;
    }
    .main,
    .jockey,
    .emergency,
    .power {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: .1s all ease;
      opacity: 1;
      @media (max-width: 600px) {
        width: 50%;
      }
    }
  }
  .alarm-status {
    display: flex;
    flex-direction: row;
    padding: 0.5em 0;
    width: 20%;
    @media (max-width: 600px) {
      flex-direction: column;
      align-items: center;
    }
  }
  .electric-generator-status {

    .fuel-level {
      .MuiLinearProgress-root[aria-label='80%'] {
        .MuiLinearProgress-bar {
          background-color: rgba(0, 150, 0, 1);
        }
      }
    }
  }
}
.title {
  margin: 0;
  padding: 0;
  justify-content: space-between;
  &__store-name,
  &__pump-status,
  &__alarm-status {
    margin: 0;
    padding: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__store-name {
    margin-left: 2rem;
    width: 3rem;
    @media (max-width: 600px) {
      margin: .5em auto;
    }
  }
  &__pump-status {
    flex-grow: 2;
    margin: 0 2em;
    @media (max-width: 600px) {
      text-align: center;
    }
  }
  &__alarm-status {
     flex-grow: 1;
     width: 20%;
  }
}

.jockey {
  .sensor-status.off {
    color: rgba(255, 150, 0, 1);
    .MuiSvgIcon-root {
      filter: drop-shadow(1px 1px 4px rgba(255, 255, 0, 1));
    }
  }
  .MuiSwitch-root {
    .MuiSwitch-switchBase:not(.Mui-checked) {
      .MuiSwitch-thumb {
        color: rgba(255, 200, 0, 1)
      }
    }
    .MuiSwitch-track {
      background-color: rgba(255, 200, 0, 1);
    }
  }
}
.connection-status {
  display: flex;
  align-items: center;
  justify-content: center;
  @keyframes connection {
    0% , 100% {
      color: rgba(0, 0, 0, 0.1);
    }
    50% {
      color: rgba(0, 0, 0, 0.3);
    }
  }
  .gray {
    animation: connection 500ms linear infinite;
  }
}
  .sensor-status {
    display: flex;
    border-radius: 100px;
    width: 46px;
    height: 46px;
    margin: 8px auto;
    justify-content: center;
    align-items: center;
    box-shadow:
      0px 0px 2px rgba(0, 0, 0, .25),
      -3px -3px 4px rgba(255, 255, 255, .7);
      transition: .5s box-shadow ease;
    @media screen and (max-width: 600px) {
      width: 30px;
      height: 30px;
    }
    &:hover {
      box-shadow:
          3px 3px 4px rgba(0, 0, 0, .5),
          -4px -4px 4px rgba(255, 255, 255, .7);
    }
  }
.green {
  color: rgba(0, 150, 0, 1);
  .MuiSvgIcon-root {
    filter: drop-shadow(1px 1px 4px rgba(0, 255, 0, 1));
  }
}
.gray {
  color: rgba(0, 0, 0, 0.3);
  .MuiSvgIcon-root {
    filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, .3));
  }
}
.red {
  color: rgba(255, 0, 0, 0.7);
  .MuiSvgIcon-root {
    filter: drop-shadow(1px 1px 4px rgba(255, 0, 0, .5));
  }
}
.yellow {
  color: rgba(255, 150, 0, 1);
  .MuiSvgIcon-root {
    filter: drop-shadow(1px 1px 4px rgba(255, 255, 0, 1));
  }
}
.MuiTableCell-root {
  // &.timestamp {}
  &.main,
  &.jockey,
  &.emergency,
  &.power {
    width: 13%;
    text-align: center;
  }
  @media screen and (max-width: 600px) {
    &.main,
    &.jockey,
    &.emergency,
    &.power {
      width: 10%;
      padding-left: 4px;
      padding-right: 4px;
      font-size: .75rem;
    }
    &.timestamp {
      font-size: .75rem;
    }
  }
}
.MuiTypography-root.title.store-name {
  text-transform: capitalize;
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 3rem;
  @media screen and (max-width: 600px) {
    font-size: 2rem;
  }
}
.store.dark,
.title.dark {
  .pump-status,
  .alarm-status {
    background-color: rgba(0, 0, 0, 0.05);
    box-shadow:
      3px 2px 5px rgba(0, 0, 0, 0.5) inset,
      -3px -3px 5px rgba(255, 255, 255, 0.15) inset,
      0 3px 25px rgba(0, 0, 0, 0.25);
  }
  .sensor-status {
    box-shadow:
      0px 0px 5px rgba(0, 0, 0, 1),
      -2px -2px 3px rgba(255, 255, 255, .25),
      3px 3px 4px rgba(0, 0, 0, .3);
    &:hover {
      box-shadow:
          3px 3px 4px rgba(0, 0, 0, .5),
          -2px -2px 3px rgba(255, 255, 255, .25);
    }
    &.green {
      color: rgba(0, 150, 0, 1);
      .MuiSvgIcon-root {
        filter: drop-shadow(0px 0px 5px rgba(0, 255, 0, 1));
      }
    }
    &.red {
      color: rgba(255, 0, 0, 1);
      .MuiSvgIcon-root {
        filter: drop-shadow(0px 0px 5px rgba(255, 0, 0, 1));
      }
    }
    &.yellow {
      color: rgba(255, 150, 0, 1);
      .MuiSvgIcon-root {
        filter: drop-shadow(0px 0px 5px rgba(255, 255, 0, 1));
      }
    }
  }
}
