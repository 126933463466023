.permissions-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  &__content {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 900px;
    flex-grow: 1;
    margin: auto;
    overflow: hidden;
    background-color: #000;
  }
}