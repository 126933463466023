.customers-container.MuiContainer-root {
  position: relative;
  display: flex;
  flex-direction: column;
  .customers-list {
    display: flex;
    flex-wrap: wrap;
  }
  .add-new-customer {
  //   position: absolute;
  //   right: 0;
    margin-bottom: 1rem;
    align-self: flex-end;
  }
}
.customers-modal {
  display: flex;
  .create-customer {
    max-width: 90vw;
    width: 500px;
    margin: auto;
    padding: 16px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .create-customer__title {
      font-size: 24px;
      font-weight: 300;
      margin: 0;
      margin-bottom: 1rem;
    }
    .customer-form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .customer-form__button {
        margin: auto;
        margin-top: 1rem;
      }
    }
  }
}