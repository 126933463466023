.page-not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #333333;
  height: 100%;
  &__logo {
    width: auto;
    height: 100px;
    margin-bottom: 3rem;
    fill: none;
    stroke-width: 10;
    stroke-linecap: round;
    stroke: #333333;
    fill: white;
    overflow: visible;
    animation: draw 5s linear infinite;
    @keyframes draw {
      0% {
        stroke-width: 0;
        stroke-dasharray: 0, 200;
        stroke-dashoffset: 0;
      }
      25% {
        stroke-width: 7;
        stroke-dasharray: 200, 0;
        stroke-dashoffset: 50;
        fill: rgba(#333333, 0);
      }
      40% {
        stroke-width: 0;
        fill: rgba(#333333, 1);
      }
      60% {
        stroke-width: 0;
        fill: rgba(#333333, 1);
      }
      75% {
        stroke-width: 7;
        stroke-dasharray: 200, 0;
        stroke-dashoffset: 50;
        fill: rgba(#333333, 0);
      }
      100% {
        stroke-width: 0;
        stroke-dasharray: 0, 200;
        stroke-dashoffset: 0;
      }
    }
  }
  &__title.MuiTypography-root {
    font-size: 8rem;
  }
  &__subtitle.MuiTypography-root {
    font-size: 3rem;
  }
  &__message.MuiTypography-root {
    font-size: 1.1rem;
    margin-bottom: 4rem;
  }
}

