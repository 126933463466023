.store-container {
  .MuiPaper-root {
    & > [class*='MuiResponsiveChart-container'] {
      width: auto;
    }
  }
  .chart-type {
    display: flex;
    justify-content: center;
  }
}