body:has(.sign-in) {
  background-color: #333333;
}
#root:has(.sign-in) {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.sign-in {
  display: flex;
  flex-direction: column;
  @media (max-width: 600px) {
    padding: 2rem 0;
  }
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2em;
    color: #ceff00;
    @media (max-width: 600px) {
      padding-top: 2rem;
    }
    .logo {
      height: 100px;
    }
    .logo-text.MuiTypography-root {
      font-size: 60px;
      font-weight: 900;
      letter-spacing: -0.05em;
      margin-left: 1.5rem;
      margin-top: 1rem;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    padding: 1em 2em;
    border-radius: 3px;
    img.tenant-logo {
      height: 40px;
      margin: 1rem auto;
    }
  }
  &__or.MuiTypography-root {
    display: flex;
    justify-content: center;
    margin: 1.5rem 0;
    text-align: center;
    position: relative;

    &::before,
    &::after {
      content: '';
      position: absolute;
      height: 0;
      margin: auto 0;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-bottom: 1px solid rgba(0,0,0,0.1);
    }
    &::before {
      right: 60%;
    }
    &::after {
      left: 60%;
    }
  }
  .sso {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    .MuiIconButton-root {
      // border: 1px solid red;
      padding: .5rem;
      background-color: #333333;
      color: #FFFF;
      margin-left: .5rem;
    }
  }
}